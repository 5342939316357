import './App.css';
import "bulma/css/bulma.min.css";
import Navbar from "./components/navbar";
import NextNavigator from "./components/nextNavigator";
import ColourSelectorPanel from "./components/colourSelector/colourSelectorPanel";
import {useEffect, useReducer, useState} from "react";
import reducer, {ACTIONS} from "./components/colourSelector/colourPaletteReducer";
import ColourPalette from "./components/colourSelector/colourPalette";
import LiveDemo from "./components/liveDemo";
import React from  "react"


function Footer() {
    let currentYear = new Date().getFullYear()
    return (
    <footer className="footer">
        <div className="content has-text-centered">
            <p>
                abha.page © {currentYear} | Made in Bharat 🇮🇳 by <a href="https://riyaj.in">Riya Jawandhiya</a> & <a href="https://surkar.in">Manthan Surkar</a>
            </p>
        </div>
    </footer>)
}

function App() {
  const [page, changePage] = useState(1);
  const [colourPalette, dispatch] = useReducer(reducer, ColourPalette());

    useEffect(() => {
            dispatch({
                type: ACTIONS.PAGE_CHANGE,
                state: colourPalette,
                payload: {
                    page: page
                }
            });
            return;
    }, [page]);


    useEffect(() => {
        dispatch({
            type: ACTIONS.FORCED_RANDOMIZE,
            state: colourPalette
        });
        return;
    }, []);

    let isDownloadView = false
    return (
    <div className="App">
        <Navbar />
        <div className="container">
            <NextNavigator state={{page, changePage, colourPalette}}/>
            <ColourSelectorPanel state={{colourPalette, dispatch, page, isDownloadView}} />
            <LiveDemo state={{colourPalette}} />
        </div>
        <Footer />
    </div>
  );
}

export default App;
